import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Buttons from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Containers from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Navbar,
  Row,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import bgImage from "../Assets/Load_41_logo.png";
import "../Assets/landingPage.css";
import Login from "../UserAuthentication/Authentication";
import { supabase } from "../firebase";

const Demo = () => {
  const [show, setShow] = useState(false);
  const [checks, setChecks] = useState(false);
  const [value, setValue] = React.useState(2);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [load, setLoad] = useState(false);
  const [loads, setLoads] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [date, setDate] = useState("");
  const [business, setBusiness] = useState("");
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [view, setView] = useState("home");
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const pages = [
    { id: "home", name: "Home", url: "/" },
    { id: "about", name: "About", url: "/about" },
    { id: "subscribe", name: "Demo", url: "/demo", className: "active-btn" },
    { id: "contact", name: "Contact", url: "/contact_us" },
    { id: "support", name: "Support", url: "/support" },
  ];
  //const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleCloseNavMenu = (id, url) => {
    navigate(url);
    //scroll(id)
    setAnchorElNav(null);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleCloseNavMenuClose = () => {
    setAnchorElNav(null);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scroll = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",
    });
    setView(id);
  };
  const reset = () => {
    setUsername("");
    setEmail("");
    setMessage("");
    setPhone("");
    setCompany("");
    setBusiness("");
    setDate("");
    setLoads("");
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const { docRef, error } = await supabase.from("subscribe").insert({
        name: username,
        email: email,
        loads: loads,
        business: business,
        phone: phone,
        company: company,
        date: date,
      });
      if (error) {
        setLoad(false);
        withReactContent(Swal).fire({
          title: <i>{error}</i>,
          icon: "error",
          timer: 2000,
        });
      } else {
        setLoad(false);
        withReactContent(Swal).fire({
          title: <i>subscribed!</i>,
          icon: "success",
          timer: 2000,
        });
      }
    } catch (e) {
      setLoad(false);
      withReactContent(Swal).fire({
        title: <i>{e}</i>,
        icon: "error",
        timer: 2000,
      });
    }
    reset();
  };
  return (
    <div className="Land_Page" id="home">
      {/*panel*/}
      {/* <div className='first_navbar'></div> */}
      {/* <Navbar className='second_navbar'> */}
      {/* <Container>
          <Nav className="me-auto">
            <Nav.Link href="#" onClick={handleShow}>Login</Nav.Link>
            <Nav.Link href="/support">Support</Nav.Link>
          </Nav>
        </Container> */}
      {/* </Navbar><br/> */}
      <Navbar className="second_navbar">
        {/* <AppBar position="static" style={{backgroundColor:'transparent',color:'black',border:'none'}}> */}
        <div className="container third_navbar d-flex align-items-center justify-content-between">
          {/* <Containers className='third'> */}
          <a
            href="/"
            className="d-flex align-items-center text-decoration-none"
          >
            <Image className="logo" src={bgImage} alt="imag" />
            <Typography noWrap className="logo-text">
              {" "}
              LOAD 41
            </Typography>
          </a>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="#555"
                sx={{ color: "#ffffff", fontSize: '40px' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenuClose}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem sx={{ color: 'black' }} key={index} onClick={() => handleCloseNavMenu(page.id, page.url)}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
          {/* <Image className='logo1' src={bgImage} alt='imag' />
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '30px',
                cursor: 'pointer'
              }}
            >
              LOAD 41
            </Typography> */}
          <Box
            className="items_navbar"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {pages.map((page, index) => (
              <Buttons
                key={index}
                onClick={() => handleCloseNavMenu(page.id, page.url)}
                className={`${page.className} d-flex align-items-center`}
              >
                {page.name}
              </Buttons>
            ))}
          </Box>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleShow}
              color="#ffffff"
              className="icon-btn p-0"
            >
              <PersonIcon sx={{ color: "#ffffff", fontSize: "40px" }} />
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#ffffff"
              className="icon-btn p-0"
              sx={{ display: { xs: "none", md: "none" } }}
            >
              <MenuIcon sx={{ color: "#ffffff", fontSize: "40px" }} />
            </IconButton>
          </div>
          {/* </Containers> */}
        </div>
        {/* </AppBar> */}
      </Navbar>
      <section className="demo-section py-5">
        <div className="container">
          <div className="default-content-box d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-4">
              <h2 className="text-start text-white h3-font h3 fw-bold mb-0">
                SUBSCRIBE US
              </h2>
              <p className="text-start text-white h6-font h6 fw-medium lh-base">
                Enhance your freight and 3PL operations...by connecting into the
                world’s largest network of carriers.
              </p>
              <ul className="mb-0">
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Over 90% load visibility
                </li>
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Increased load bookings without needing more staff
                </li>
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Enhanced trust in carrier relationships
                </li>
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Earlier fraud detection
                </li>
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Smooth cross-border tracking
                </li>
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Say goodbye to manual load tracking{" "}
                </li>
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Say goodbye to carrier check calls{" "}
                </li>
                <li className="text-start text-white h6-font h6 fw-medium lh-base">
                  Say goodbye to imprecise guesswork
                </li>
              </ul>
              <p className="text-start text-white h6-font h6 fw-medium lh-base">
                Load 41 simplifies freight and 3PL management, making it
                seamless, swift, and cost-effective.
              </p>
            </div>

            <div>
              {/* <Col xs={12} md={6} xl={6}>
        <h2>REVIEW</h2>
        <Row>
          <Col xs={12} md={12} xl={12} style={{border:'10px solid #fff',marginBottom:'7px',textAlign:'left',padding:'5%'}}>
          <Row>
            <Col>
          <h3>Yael A.</h3>
          <p>Manager</p>
          </Col>
          <Col>
          <Box
          sx={{
            '& > legend': { mt: 2 },
          }}
        >
          <Typography component="legend">Controlled</Typography>
          <Rating
            name="simple-controlled"
            value={value}
            readOnly
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          </Box>
          </Col>
          </Row>
          <p style={{fontSize:'1.5em'}}>Streamlined logistics, boosted efficiancy, a game-changer in freight</p>
          </Col>
          <Col xs={12} md={12} xl={12} style={{border:'10px solid #fff',textAlign:'left',padding:'5%'}}>
          <Row>
          <Col>
          <h3>Chad O.</h3>
          <p>Logistics</p>
          </Col>
          <Col>
          <Box
          sx={{
            '& > legend': { mt: 2 },
          }}
        >
          <Typography component="legend">Controlled</Typography>
          <Rating
            name="simple-controlled"
            value={value}
            readOnly
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          </Box>
          </Col>
          </Row>
          <p style={{fontSize:'1.5em'}}>Load41 revolutionized our shipping process, making logistics effortless and efforts</p>
          </Col>
        </Row>
        </Col> */}

              <div className="d-flex flex-column gap-4">
                <h2 className="text-start text-white h3-font h3 fw-bold mb-0">
                  BOOK YOUR DEMO
                </h2>
                <p className="text-start text-white h6-font h6 fw-medium lh-base">
                  Ready to see Load 41 in action? Fill out the form below to
                  schedule your live demo.
                </p>
                <Form onSubmit={onSubmit} className="row web-form-wrap">
                  <Form.Group
                    className="mb-3 col-md-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* <Form.Label>Name</Form.Label> */}
                    <Form.Control
                      type="text"
                      rows={2}
                      placeholder="NAME"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                    {/* {!username?.length && <p style={{color:'red',fontSize:15}}>please enter username</p>} */}
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="email"
                      rows={2}
                      placeholder="EMAIL"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {/* {!email?.length && <p style={{color:'red',fontSize:15}}>please enter email</p>} */}
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="text"
                      rows={2}
                      placeholder="PHONE"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    {/* {!email?.length && <p style={{color:'red',fontSize:15}}>please enter email</p>} */}
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="text"
                      rows={2}
                      placeholder="COMPANY NAME"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                    {/* {!email?.length && <p style={{color:'red',fontSize:15}}>please enter email</p>} */}
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="datetime-local"
                      rows={2}
                      placeholder="Preferred Date & Time"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    {/* {!email?.length && <p style={{color:'red',fontSize:15}}>please enter email</p>} */}
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      rows={2}
                      aria-label="Default select example"
                      value={loads}
                      onChange={(e) => setLoads(e.target.value)}
                      required
                    >
                      <option># of Brokered Loads Per Month</option>
                      <option value="0-50">0-50</option>
                      <option value="51-350">51-350</option>
                      <option value="351-450">351-450</option>
                      <option value="451-1000">451-1000</option>
                      <option value="1000+">1000+</option>
                    </Form.Select>
                  </Form.Group>
                  {/* <Form.Group className="mb-3 col-md-4" controlId="exampleForm.ControlInput1"> */}
                  {/* <Form.Label>Email address</Form.Label> */}
                  {/* <Form.Control type="text" rows={2} placeholder="# of Brokered Loads Per Month" value={loads} onChange={(e)=>setLoads(e.target.value)} required/> */}
                  {/* {!email?.length && <p style={{color:'red',fontSize:15}}>please enter email</p>} */}
                  {/* </Form.Group> */}
                  <Form.Group
                    className="mb-3 col-md-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      rows={2}
                      aria-label="Default select example"
                      value={business}
                      onChange={(e) => setBusiness(e.target.value)}
                      required
                    >
                      <option>Business Type</option>
                      <option value="Carrier">Carrier</option>
                      <option value="Broker">Broker</option>
                      <option value="3PLs">3PLs</option>
                      <option value="Shipper">Shipper</option>
                    </Form.Select>
                  </Form.Group>
                  {/* <Form.Group className="mb-3 col-md-4" controlId="exampleForm.ControlInput1"> */}
                  {/* <Form.Label>Email address</Form.Label> */}
                  {/* <Form.Control type="text" rows={2} placeholder="Business Type" value={business} onChange={(e)=>setBusiness(e.target.value)} required/> */}
                  {/* {!email?.length && <p style={{color:'red',fontSize:15}}>please enter email</p>} */}
                  {/* </Form.Group> */}
                  <Form.Group className="form-control-label text-start">
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          value={checks}
                          onChange={() => setChecks(!checks)}
                        />
                      }
                      label="By submitting this form, you consent to receiving valuable emails from Load 41. We prioritize your privacy and do not share your information with third-party sites or affiliate companies. You can opt out at any time."
                    />
                  </Form.Group>
                  <button
                    className="custom-btn mt-4 mx-auto col-sm-4"
                    type="submit"
                  >
                    {load ? "loading" : "SUBMIT"}
                  </button>
                </Form>
                {/* <p style={{fontSize:'20px'}}><LocalPhoneIcon/> 1 402 714 5727</p>
            <p style={{fontSize:'20px'}}><MailIcon/> <a href='mailto:support@load41.com' style={{color:'#fff',textDecoration:'none'}}>support@load41.com</a></p> */}
                {/* <p style={{fontSize:'20px'}}><HelpIcon/> <Link to='/support' style={{color:'#fff',textDecoration:'none'}}>Support</Link></p> */}
              </div>
            </div>

            <div>
              {/* <Col xs={12} md={6} xl={6} style={{textAlign:'left'}}>
        <h3>Address</h3>
        <p style={{fontSize:'1em'}}>
        If you prefer to contact us via mail or visit our office, you can find us at:<br/>
        Load 41<br/>
        140 S. 27th Street, Suite A<br/>
        Lincoln, NE 68510<br/>
        </p>
        </Col> */}
              <div className="d-flex flex-column gap-4">
                <h2 className="text-start text-white h3-font h3 fw-bold mb-0">
                  Business Hours
                </h2>
                <p className="text-start text-white h6-font h6 fw-medium lh-base">
                  Our team is available to assist you during the following
                  hours:
                </p>
                <p className="text-start text-white h6-font h6 fw-medium lh-base">
                  Call us at{" "}
                  <a href="tel:+1-402-714-5726" className="text-black">
                    +1-402-714-5726
                  </a>
                </p>
                <p className="text-start text-white h6-font h6 fw-medium lh-base">
                  Monday - Friday: 9:00 AM - 6:00 PM CST
                </p>
                <p className="text-start text-white h6-font h6 fw-medium lh-base">
                  Saturday: Closed
                </p>
                <p className="text-start text-white h6-font h6 fw-medium lh-base">
                  Sunday: Closed
                </p>
              </div>
            </div>

            <div>
              <h4 className="text-center mt-4 text-white h4-font h4 fw-medium mb-0">
                Thank you for choosing Load 41. We look forward to assisting
                you!
              </h4>
            </div>
          </div>
        </div>
      </section>
      <footer className="d-flex align-items-center justify-content-center">
        <div
          className="container text-center text-clr-white d-flex align-items-center justify-content-between"
          id="disclaimer"
        >
          <h6 className="mb-0 h6-font h6">
            &copy; 2024 Load 41. All rights reserved.
          </h6>
          <Link
            to="/privacy_policy"
            className="hover-text-light-white text-clr-white h6-font h6 mb-0"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms_and_conditions"
            className="hover-text-light-white text-clr-white h6-font h6 mb-0"
          >
            Terms of Service
          </Link>
        </div>
      </footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ backgroundColor: "#555" }}>
          <Login />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Demo;
