import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Buttons from "@mui/material/Button";
import Containers from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import bgImage from "../Assets/Load_41_logo.png";
import "../Assets/landingPage.css";
import Login from "../UserAuthentication/Authentication";
const NotFound = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const pages = [
    { id: "home", name: "Home", url: "/" },
    { id: "about", name: "About", url: "/about" },
    { id: "subscribe", name: "Demo", url: "/demo" },
    { id: "contact", name: "Contact", url: "/contact_us" },
    { id: "support", name: "Support", url: "/support" },
  ];
  //const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenuClose = () => {
    setAnchorElNav(null);
  };
  const handleCloseNavMenu = (id, url) => {
    navigate(url);
    //scroll(id)
    setAnchorElNav(null);
    setAnchorElNav(null);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scroll = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className="Land_Page" id="home">
      {/*panel*/}
      {/* <div className='first_navbar'></div> */}
      {/* <Navbar className='second_navbar'> */}
      {/* <Container>
        <Nav className="me-auto">
          <Nav.Link href="#" onClick={handleShow}>Login</Nav.Link>
          <Nav.Link href="/support">Support</Nav.Link>
        </Nav>
      </Container> */}
      {/* </Navbar><br/> */}
      <Navbar className="second_navbar">
        {/* <AppBar position="static" style={{backgroundColor:'transparent',color:'black',border:'none'}}> */}
        <div className="container third_navbar d-flex align-items-center justify-content-between">
          {/* <Containers className='third'> */}
          <a
            href="/"
            className="d-flex align-items-center text-decoration-none"
          >
            <Image className="logo" src={bgImage} alt="imag" />
            <Typography noWrap className="logo-text">
              {" "}
              LOAD 41
            </Typography>
          </a>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="#555"
                sx={{ color: "#ffffff", fontSize: '40px' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenuClose}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem sx={{ color: 'black' }} key={index} onClick={() => handleCloseNavMenu(page.id, page.url)}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
          {/* <Image className='logo1' src={bgImage} alt='imag' />
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '30px',
                cursor: 'pointer'
              }}
            >
              LOAD 41
            </Typography> */}
          <Box
            className="items_navbar"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {pages.map((page, index) => (
              <Buttons
                key={index}
                onClick={() => handleCloseNavMenu(page.id, page.url)}
                className={`${page.className} d-flex align-items-center`}
              >
                {page.name}
              </Buttons>
            ))}
          </Box>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleShow}
              color="#ffffff"
              className="icon-btn p-0"
            >
              <PersonIcon sx={{ color: "#ffffff", fontSize: "40px" }} />
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#ffffff"
              className="icon-btn p-0"
              sx={{ display: { xs: "none", md: "none" } }}
            >
              <MenuIcon sx={{ color: "#ffffff", fontSize: "40px" }} />
            </IconButton>
          </div>
          {/* </Containers> */}
        </div>
        {/* </AppBar> */}
      </Navbar>
      <div>
        <Container
          style={{
            color: "#fff",
            height: "54vh",
            paddingTop: "3%",
            background: "transparent",
          }}
        >
          <h1 style={{ color: "#fff" }}>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for could not be found.</p>
        </Container>
      </div>
      <footer className="d-flex align-items-center justify-content-center">
        <div
          className="container text-center text-clr-white d-flex align-items-center justify-content-between"
          id="disclaimer"
        >
          <h6 className="mb-0 h6-font h6">
            &copy; 2024 Load 41. All rights reserved.
          </h6>
          <Link
            to="/privacy_policy"
            className="hover-text-light-white text-clr-white h6-font h6 mb-0"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms_and_conditions"
            className="hover-text-light-white text-clr-white h6-font h6 mb-0"
          >
            Terms of Service
          </Link>
        </div>
      </footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ backgroundColor: "#555" }}>
          <Login />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NotFound;
